<template>
  <v-app>
    <ajax-loading-bar />
    <v-layout>
      <app-bar />
      <v-main>
        <v-container fluid>
          <notifier-messages />
          <router-view />
        </v-container>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script setup lang="ts">
import { useTitle } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import AjaxLoadingBar from '@/components/Widgets/AjaxLoadingBar.vue';
import AppBar from '@/components/Widgets/AppBar.vue';
import NotifierMessages from '@/components/Widgets/NotifierMessages.vue';

const {t} = useI18n();
const title = useTitle();
title.value = t('meta.browserTitle');
</script>

<style>
.required label::after {
  content: " *";
  /*color: red;*/
}

/* this is a workaround for dialog boxes being transparent on top of a v-scrim overlay. It will fail if we use a dark theme */
.v-overlay__content > .v-card {
  background-color: white;
}

/* override vuetify which sets the text-align to center */
.v-data-table-rows-no-data, .v-data-table-rows-loading {
  text-align: left !important;
}

tbody.v-data-table__tbody tr {
  vertical-align: top !important;
}

.table-auto-width {
  width: auto !important;
  table-layout: auto;
  display: inline-table;
}

.content-block h2, .content-block h3, .content-block h4 {
  margin-top: 1.1rem;
  margin-bottom: 0.5rem;
}

.content-block p {
  margin-top: 0.5rem;
}

.content-block ul li, .content-block ol li {
  margin-top: 0.25rem;
  margin-left: 1.2rem;
}

.content-block img {
  max-width: 100%;
  height: auto;
}

.content-block table {
  margin-top: 0.5rem;
  border-collapse: collapse;
}

.content-block th {
  text-align: left;
  padding: 0 0.5rem 0.5rem 0;
}

.content-block td {
  padding: 0 0.5rem 0.5rem 0;
}

/* put a red border around assessment button fields that are empty but required */
.assessment .v-sheet :has(.v-input--error) {
  border: 2px solid rgb(var(--v-theme-error));
}
</style>
